import { EventsService } from './api/events/events.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { SocketClientService } from 'src/app/services/socket-client.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false,
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'sample';
  user!: any;

  constructor(
    private authServ: AuthService,
    private eventServ: EventsService,
    private socketClientService: SocketClientService
  ) {}

  ngOnInit(): void {
    // Get User data
    this.authServ.users$.subscribe((res: any) => {
      this.user = res;
      if (this.user) {
        this.eventServ?.eventData$?.subscribe((res:any) => {
          if (res) {
            this.socketClientService.openConnection(res.id);
          } else {
            this.eventServ.getEventData().then((res: any) => {
              if (res) {
                this.socketClientService.openConnection(res.id);
              }
            });
          }
        });
      }
    });
  }
}
