
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { HelperService } from 'src/app/services/helper.service';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private helper: HelperService
    ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const errMsg = err?.error?.message
        switch (err.status) {
          case 400:
            // this.auth.errorLog();
            console.error(errMsg);
            this.helper.showAlert('', errMsg, 'info')
            break;

          case 500:

            if(errMsg){
              console.error(errMsg);
              this.helper.showAlert('', errMsg, 'info')
            }

          break;
          case 302:
          case 401:
            //JWT Expired
            console.error(errMsg);
            this.helper.showAlert('', 'Sesi Anda telah berakhir.<br>Silakan masuk kembali.', 'info').then(res=>{
              if(res){
                this.auth.logout_end_session();
                this.auth.logout();
                this.auth.logoutAdfs();
              }
            })
          break;


          default:
            console.error(errMsg);
            this.helper.showAlert('', err?.message, 'info')
            break;
        }


        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}


